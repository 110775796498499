import React from 'react';
import { Container, Col, Row, Button } from "react-bootstrap";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import TeamContent from "../components/Teams/TeamDetail";
import BottomDarkBanner from '../components/BottomDarkBanner/BottomDarkBanner';
import {LinkedinIcon} from "react-share";

const TeamDetail = ({data}) => {

    const teamData = data.glstrapi.team;
    const customModule = {
      Heading : 'ARE YOU IN A CHAIN?',
      Title : 'Guarantee your property sale',
      CTA_Label: 'Get your FREE instant Quote',
      CTA_Link: {slug: 'contact-clozesure', label: 'Contact Us'}
    }


    return (
        <>
            <Header />
              <TeamContent teamData= {teamData} />             
              <BottomDarkBanner module={customModule} />
            <Footer />
        </>
    )
}

export default TeamDetail

export const pageQuery = graphql`
  query GetTeamsPage($articleId: ID!) {
    glstrapi {
      team(id: $articleId, publicationState: LIVE) {
        id
        slug
        title
        name
        designation
        email
        mobile_no
        content
        video_url
        linkedin
        tile_image {
            url
            alternativeText
        }
      }
    }
  }
`;