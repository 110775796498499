import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Link } from 'gatsby';
import parse from 'html-react-parser';
import { LinkedinIcon } from 'react-share';
import { useMediaQuery } from '../../../src/hooks/useMediaQuery';
import './TeamDetail.scss';

const TeamDetail = (props) => {
    const isDesktopOrLaptop = useMediaQuery("(min-width: 768px)");
    return (
        <section className={`section-p team-detail-page ${isDesktopOrLaptop ? 'desktop-team-layout' : 'mobile-team-layout'}`}>
            <Container className="team-detail-container">
                {isDesktopOrLaptop ? <DesktopTemplate {...props} /> : <MobileTemplate {...props} />}
            </Container>
        </section>
    );
}
const TeamDetailHeader = (props) => {
    return (
        <div className="content-section">
            <h4 className="heading">Our Team</h4>
            <h1 className="title">{props.teamData.name}</h1>
            <h3 className="designation">{props.teamData.designation}</h3>
        </div>
    );
}
const TeamDetailImg = (props) => {
    return (
        <div className="team-img-section">
            <img src={props?.imgData?.url} alt={props?.imgData?.alternativeText || props?.imgData?.name} className="img-fluid member-img" />
        </div>
    );
}
const TeamDetailLinkedin = (props) => {
    return (
        <>
        <div className="team-details-border-line-sm"></div>
        {props.linkedin && <Link to={props.linkedin} target="_blank" rel="noopener noreferrer" className='team-links linkedin-link'>
            <span className="link-text">Find me on Linkedin</span> <LinkedinIcon size={18} round={false} borderRadius="5px" bgStyle={{ fill: '#2D3748' }} />
        </Link>}
        </>
    );
}
const BackToTeamLink = () => {
    return (
        <Link to="/meet-the-team/" className="back-link team-links">
            Back to team
        </Link>
    );
};

const DesktopTemplate = (props) => {
    return (
        <Row className='team-detail-row'>
            <Col md={5} className="team-detail-left-col">
                <TeamDetailImg imgData={props?.teamData?.tile_image} name={props?.teamData.name} />
                <BackToTeamLink />   
            </Col>
            <Col md={7} className="team-detail-right-col">                
                <TeamDetailHeader teamData={props?.teamData} />               
                <div className="member-description">{parse(props?.teamData?.content)}</div>   
                 <TeamDetailLinkedin linkedin={props?.teamData.linkedin} />
            </Col>
        </Row>
    );
}

const MobileTemplate = (props) => {
    return (
        <>
            <Row className='team-detail-row'>
                <Col className="team-detail-left-col">
                    <BackToTeamLink />               
                    <TeamDetailHeader teamData={props?.teamData} />
                    <TeamDetailImg imgData={props?.teamData?.tile_image} name={props?.teamData.name} />              
                </Col>
            </Row>
            <Row className='team-detail-row'>
                <Col className="team-detail-right-col">
                    <div className="member-description">{parse(props?.teamData?.content)}</div>
                    {props?.teamData.linkedin &&  <TeamDetailLinkedin linkedin={props?.teamData.linkedin} /> }
                </Col>
            </Row>
        </>
    );
}

export default TeamDetail;