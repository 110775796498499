import { Link } from 'gatsby';
import React from 'react';
import {GetPageURL} from '../common/utils';
import { Container, Col } from 'react-bootstrap';
import './BottomDarkBanner.scss'
function BottomDarkBanner({module,GQLPage}) {
    const handleScroll = ()=>{
        const getId = document.getElementById("home-page-form")
        if (getId) {
            getId.scrollIntoView({behavior:"smooth",block: "start"}); 
        }
    }
  return (
    <section className='section-btm-banner text-center'>
        <Container>
            <Col lg={10} xxl={8} className="m-auto">
                <h4>{module.Heading}</h4>
                <h2>{module.Title}</h2>
                {module.CTA_Link &&
                <div className='d-flex justify-content-center'>
                    {GQLPage?.title === "Home"?
                    <button onClick={handleScroll} className='btn btn-pink'>{module?.CTA_Label}</button>   
                    : 
                    <Link className='btn btn-pink' to={GetPageURL(module.CTA_Link)}>
                        {module?.CTA_Label}
                    </Link>
                    }
                </div>
                }
            </Col>
        </Container>
    </section>
  );
}

export default BottomDarkBanner;
